.app-container {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
}

.app-bar {
    background-color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 50px;
    margin: 10px;
    position: fixed;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    z-index: 11;
}

.logo {
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
}

.logoText {
    height: 25px;
}

.divider {
    background-color: white;
}