@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
  padding: 20px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.pulse-container {
  position: relative;
  width: 25px;
  height: 25px;
}

.pulse-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(10, 147, 150, 0.5);
  /* Light teal color with some transparency */
  animation: pulse 1.5s infinite;
}

.solid-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: teal;
  /* Solid teal color */
}

@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/OpenSans-VariableFont.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansItalic";
  src: url("./assets/fonts/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceMono";
  src: url("./assets/fonts/SpaceMono-Regular.ttf") format("truetype");
}

body,
html,
* {
  font-family: "OpenSans", sans-serif;
}

* {
  font-family: "OpenSans", sans-serif;
}

.avatar-container {
  justify-content: center;
  display: flex;
}

.store-name-box {
  font-size: 30px;
  margin-top: 16px;
  /* mt-2 */
}

.store-details-container {
  display: flex;
  justify-content: center;
  color: gray;
  gap: 0.5rem;
}

.rating-container {
  display: flex;
  align-items: center;
}

.text-container {
  display: flex;
  align-items: center;
  word-break: break-word;
  /* Ensure long text wraps correctly */
}

.download-container {
  background-color: lightgrey;
  border-radius: 15px;
  margin: 5px;
  padding: 15px;
  align-content: center;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.app-download-container {
  gap: 5px;
  display: flex;
  /* background-color: pink; */
  justify-content: center;
  flex-wrap: wrap;
}

.app-logo {
  width: 150px;
}

button.button-common {
  border-radius: 10px !important;
  padding: 0 20px !important;
  font-size: 15px !important;
  font-weight: bold !important;
  border-color: white !important;
}

.footer {
  width: 100%;
  background-color: #f1f1f1;
  padding: 5px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  border-top: 1px solid #ddd;
}

.view-container {
  min-height: 100vh;
  padding-right: 25px;
  padding-left: 25px;
}
