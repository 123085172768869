.box-container {
    padding: 0px 25px 0px 25px;
    height: 100vh;
}

.social-icon-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
}