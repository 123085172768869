.events-container {
    height: 92vh;
}

.events-box-container {
    padding: 0px 25px 0px 25px;
    height: 100vh;
}

.events-title {
    color: white;
    font-weight: 900;
}

.events-description {
    color: white;
    font-weight: 200;
}

.host-button {
    font-weight: 800 !important;
    border-radius: 40px !important;
}

.event-icon {
    height: 15rem
}

.manage-card-icon-circle {
    font-size: 30px;
    transition: transform 0.3s ease-in-out, all 0.3s ease-in-out;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    /* Ensures the circle is well-proportioned */
    height: 40px;
}